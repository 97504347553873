import React from "react";

function ExploreCourseHerder() {
    return (
        <>
            <div className="ExplorecourseHeader">
                <h2 style={{textAlign:"start"}}>Program Overview</h2>
                <p>
                    The Online Post Graduate Diploma in Management (PGDM) program is designed for working professionals and aspiring leaders who seek to enhance their business acumen and managerial skills. This program offers a flexible, industry-relevant curriculum that covers key areas such as marketing, finance, operations, human resources, and strategic management. With a blend of theoretical knowledge and practical applications, students gain a well-rounded understanding of modern business practices.
                </p>
                <p>
                    The program is delivered through interactive online sessions, real-world case studies, and hands-on projects, ensuring that learners acquire practical insights applicable to their industries. Guided by expert faculty and industry leaders, students develop critical thinking, problem-solving, and leadership abilities essential for career growth.
                </p>
                <p>
                    Whether you are looking to accelerate your current career, switch industries, or start your own venture, the Online PGDM program provides the skills and knowledge necessary to thrive in a dynamic business environment. With access to networking opportunities, career support services, and a globally recognized curriculum, graduates of this program are well-equipped to take on managerial and leadership roles across various sectors.
                </p>
            </div>

        </>
    )
}
export default ExploreCourseHerder