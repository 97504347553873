import Logo from './Logo/collegelogo (1).png'
import LogoInner from './Logo/logoInner.png'
import Logoinnerblack from './Logo/collegename.png'
import landingAsset from './landing/1.png'
import Edu_home from './banners/edu_home.webp'
import Jain_University_logo from './Logo/Jain_University.png'
import Chandigarh_University_logo from './Logo/Chandigarh_University.png'
import Noida_International_University_logo from './Logo/Noida_nternational_University.png'
import DY_Patil_University_logo from './Logo/Group_1171275172.png'
import KIIT_University_logo from './Logo/KIIT_University.png'
import Dr_MGR_University_logo from './Logo/Dr_MGR_University.png'
import Andhra_University_logo from './Logo/Andhra_University.png'
import Kurukshetra_University_logo from './Logo/Kurukshetra_University.png'
import OP_Jindal_Global_University_logo from './Logo/OP_Jindal_Global_University.png'
import Vivekananda_Global_University_logo from './Logo/Vivekananda_Global_University.png'
import Bharathidasan_University_logo from './Logo/Bharathidasan_University.png'
import Koneru_Lakshmaiah_Education_Foundation_KL_University_logo from './Logo/Koneru_Lakshmaiah_Education_Foundation_(KL University).png'
import Vignan_University_logo from './Logo/Vignan_University.png'
import Andhra_University from './colloge background/Andhra_University.jpeg'
import Bharathidasan_University from './colloge background/Bharathidasan_University.jpeg'
import Centurion_University from './colloge background/Centurion_University.jpeg'
import Chandigarh_University from './colloge background/Chandigarh_University.jpeg'
import DYPatil_University from './colloge background/D.Y. Patil_University.jpeg'
import DrMGR_University from './colloge background/Dr.MGR_University.jpeg'
import Jain_University from './colloge background/Jain_University.jpeg'
import KIIT_University from './colloge background/Jain_University.jpeg'
import Koneru_Lakshmaiah_Education_Foundation from './colloge background/Koneru_Lakshmaiah_Education_Foundation_(KL University).jpeg'
import Kurukshetra_University from './colloge background/Kurukshetra_University.png'
import Noida_International_University from './colloge background/Noida_International_University.jpeg'
import OP_Jindal_Global_University from './colloge background/OP_Jindal_Global_University.jpeg'
import Vignan_University from './colloge background/Vignan_University.png'
import Vivekananda_Global_University from './colloge background/Vivekananda_Global_University.jpeg'
import academic from './Academic/academic.png'
import profile from './Academic/profile.png'
import kaviya from './Academic/kaviya.png'
import Gowtham from './Academic/Gowtham.png'
import Ananth from './Academic/Ananth.png'
import Deepak from './Academic/Deepak.png'
import Keerthi from './Academic/Keerthi.png'
import Sreya from './Academic/Sreya.png'
import Janani from './Academic/Janani.png'
import SriRam from './Academic/SriRam-.png'
import Sinthu from './Academic/Sinthu.png'
import Karthik from './Academic/Karthik.png'
import Mathan from './Academic/Mathan.png'
import Suba from './Academic/Suba.png'
import Anu from './Academic/Anu.png'
import Siva from './Academic/Siva.png'
import Harish from './Academic/Harish.png'
import Sri from './Academic/Sri.png'
import Selvi from './Academic/Selvi.png'
import priya from './Academic/Priya.png'
import Diya from './Academic/Diya.png'
import Kumar from './Academic/Kumar.png'
import Maha from './Academic/Maha.png'
import Krishna from './Academic/Krishna.png'
import Pavi from './Academic/Pavi.png'
import graduation from './Academic/graduation.png'
import globe1 from './Academic/globe1.gif'
import edu1 from './Logo/education.png'
import edu2 from './Logo/graduated.png'
import edu3 from './Logo/graduation.png'
import studyabrod from './Logo/study-abroad.png'
import outlook from './Blog/outlook.png'
import financial from './Blog/financialexpress.png'
import indiatoday from './Blog/indiatoday.png'
import yourstory from './Blog/yourstory.png'
import outlookpage from './Blog/outlookpage.png'
import indiapage from './Blog/indiapage.png'
import telugutoday from './Blog/telugutoday.png'
import QR from './Academic/QR.png'
import wallet from './Academic/wallet.png'
import loan from './Academic/loan.png'
import career1 from './Academic/career1.png'
import career2 from './Academic/career2.png'

const Assets = {
    Logo: Logo,
    LogoInner: LogoInner,
    Logoinnerblack: Logoinnerblack,
    landingAsset: landingAsset,
    Edu_home: Edu_home,
    Jain_University_logo: Jain_University_logo,
    Chandigarh_University_logo: Chandigarh_University_logo,
    Noida_International_University_logo: Noida_International_University_logo,
    DY_Patil_University_logo: DY_Patil_University_logo,
    KIIT_University_logo: KIIT_University_logo,
    Dr_MGR_University_logo: Dr_MGR_University_logo,
    Andhra_University_logo: Andhra_University_logo,
    Kurukshetra_University_logo: Kurukshetra_University_logo,
    OP_Jindal_Global_University_logo: OP_Jindal_Global_University_logo,
    Vivekananda_Global_University_logo: Vivekananda_Global_University_logo,
    Bharathidasan_University_logo: Bharathidasan_University_logo,
    Koneru_Lakshmaiah_Education_Foundation_KL_University_logo: Koneru_Lakshmaiah_Education_Foundation_KL_University_logo,
    Vignan_University_logo: Vignan_University_logo,
    Andhra_University: Andhra_University,
    Bharathidasan_University: Bharathidasan_University,
    Centurion_University: Centurion_University,
    Chandigarh_University: Chandigarh_University,
    DYPatil_University: DYPatil_University,
    DrMGR_University: DrMGR_University,
    Jain_University: Jain_University,
    KIIT_University: KIIT_University,
    Koneru_Lakshmaiah_Education_Foundation: Koneru_Lakshmaiah_Education_Foundation,
    Kurukshetra_University: Kurukshetra_University,
    Noida_International_University: Noida_International_University,
    OP_Jindal_Global_University: OP_Jindal_Global_University,
    Vignan_University: Vignan_University,
    Vivekananda_Global_University: Vivekananda_Global_University,
    academic:academic,
   profile:profile,
   kaviya:kaviya,
   Gowtham:Gowtham,
   Ananth:Ananth,
   Deepak:Deepak,
   Keerthi:Keerthi,
   Sreya:Sreya,
   Janani:Janani,
   SriRam:SriRam,
   Sinthu:Sinthu,
   Karthik:Karthik,
   Suba:Suba,
   Mathan:Mathan,
   Anu:Anu,
   Siva:Siva,
   Harish:Harish,
   Sri:Sri,
   Selvi:Selvi,
   priya:priya,
   Diya:Diya,
   Kumar:Kumar,
   Maha:Maha,
   Krishna:Krishna,
   Pavi:Pavi,
   graduation:graduation,
   globe1:globe1,
   edu1:edu1,
   edu2:edu2,
   edu3:edu3,
   studyabrod:studyabrod,
   edu1:edu1,
   edu2:edu2,
   edu3:edu3,
   studyabrod:studyabrod,
   outlook:outlook,
   indiatoday:indiatoday,
   financial:financial,
   yourstory:yourstory,
   outlookpage:outlookpage,
   indiapage:indiapage,
   telugutoday:telugutoday,
   QR:QR,
   wallet:wallet,  
   loan:loan,
   career1:career1,
   career2,career2,


}
export default Assets