import Aboutsus from "../pages/Aboutsus"
import ExploreCourse from "../pages/ExploreCourse"
import Exploreuniversities from "../pages/Exploreuniversities"
import Login from "../pages/Header_files/Login"
import Site_map from "../pages/Header_files/Site_map"
import Home from "../pages/Home"
import RegForm from "../pages/RegForm"


const route = [
    { path: "/", name: "Dashboard", element: Home, exact: true },
    { path: "/about-us", name: "About us", element: Aboutsus, exact: true },
    { path: "/sitemap", name: "site Map", element: Site_map, exact: true },
    { path: "/login", name: "Login", element: Login, exact: true },
    { path: "/explore-courses", name: "course", element: ExploreCourse, exact: true },
]
export default route